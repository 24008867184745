import React from 'react';
import Welcome from './helpers/Welcome';

export default function Home() {

  return (
    <div className='row'>
      <Welcome />
    </div>
  );
}
